import PageEnum from '@/enums/pageEnum';
import { useEffect } from 'react';
import { history } from 'umi';

// 这个是网站打开的第一个组件
const RedirectComponent = () => {
  useEffect(() => {
    const isMobile =
      /Android|iPhone|iPad|iPod|Mobile/i.test(navigator.userAgent) ||
      window.innerWidth <= 768;

    if (isMobile) {
      // 手机跳转登录
      history.replace(PageEnum.LOGIN);
    } else {
      history.replace(PageEnum.PCHOME);
    }
  }, []);

  return null;
};

export default RedirectComponent;
